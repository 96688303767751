html {
  font-size: 62.5% !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.6rem;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: #96D8FB;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#root {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(130.1deg, #BF26E6 3.94%, #3C14DA 98.4%);
}

.fadeInfadeOut {
  opacity: 1;
  animation: fade 2s linear;
}


@keyframes fade {
0% { opacity: .3 }
50% { opacity: 1 }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* width */
#slider::-webkit-scrollbar {
  height: 10px;
}

/* Track */
#slider::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #252E52; 
  border-radius: 10px;
}
 
/* Handle */
#slider::-webkit-scrollbar-thumb {
  background: #BF26E6; 
  border-radius: 10px;
}

/* Handle on hover */
#slider::-webkit-scrollbar-thumb:hover {
  background: #68F9FC; 
}